
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import Pagination from "@/comp/Pagination/index.vue";
import { getH5ActivityList, getLedgerAll, addH5Activity, updateH5Activity, deleteH5Activity, getH5ActivityRank, reCalH5Activity } from "@/api/request/activity";

//组件
@Component({
  name: "H5Manager",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private propList: any[] = [];
  private listLoading: boolean = false;

  //请求参数
  private listQuery: any = {
    //页面数据
    page: 1, //分页
    page_size: 10, //每页行数

    //临时数据
    time: [],

    //请求参数
    type: 0, //活动类型
    name: "", //活动名称
    end_time: "", //创建时间结束
    start_time: "", //创建时间开始
  };

  //类型列表
  private typeList: any[] = [
    { type: 0, name: "全部类型" },
    { type: 1, name: "赠送道具积分排名" },
  ];

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
    };
  }

  //创建
  created() {
    //获取道具列表
    this.getPropList();

    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //时间赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }

    //获取数据
    const { data } = await getH5ActivityList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取道具列表
  private async getPropList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getLedgerAll();

    //数据赋值
    this.propList = data;
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理创建
  private async handleCreate() {
    //数据赋值
    this.form = {
      //临时数据
      time: [], //时间
      rate_str: "", //钻石积分比例

      //上传参数
      id: 0, //ID
      type: 0, //活动类型
      name: "", //活动名字
      rate: 0, //钻石积分比例
      h5_url: "", //h5活动链接
      params: "", //辅助参数
      end_time: "", //活动结束时间
      start_time: "", //活动开始时间
    };
    this.isCreate = true;
    this.addPropList = [];

    //显示对话框
    this.dialogShow = true;
  }

  //处理编辑
  private handleEdit(row: any): void {
    //数据赋值
    this.form = {
      //临时数据
      rate_str: String(row.rate), //钻石积分比例
      time: [row.start_time_str, row.end_time_str], //时间

      //上传参数
      id: row.id, //ID
      name: row.name, //活动名字
      rate: row.rate, //钻石积分比例
      params: row.exts, //辅助参数
      h5_url: row.h5_url, //h5活动链接
      type: String(row.type), //活动类型
      end_time: row.end_time_str, //活动结束时间
      start_time: row.start_time_str, //活动开始时间
    };
    this.isCreate = false;

    //辅助参数赋值
    this.addPropList = [];
    var propIDList = this.getExts(row.exts);
    for (var i: number = 0; i < propIDList.length; i++) {
      //数据赋值
      var propID: number = propIDList[i];
      this.addPropList.push({ propid: propID, propname: this.getPropName(propID) });
    }

    //显示对话框
    this.dialogShow = true;
  }

  //处理删除
  private handleDelete(row: any): void {
    //显示提示
    this.$confirm("确定删除活动<" + row.name + ">？", "警告", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //请求删除
        const res: any = await deleteH5Activity({ id: row.id });

        //删除成功
        if (res.status == 0) {
          //显示提示
          this.$message.success("删除成功");

          //获取数据
          this.getList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //处理排行榜
  private handleRanking(row: any): void {
    //数据赋值
    this.rankingQuery = {
      //请求数据
      id: row.id, //活动ID

      //页面数据
      page: 1, //分页
      page_size: 10, //每页行数
    };

    //获取数据
    this.getRankingList();

    //显示对话框
    this.rankingDialogShow = true;
  }

  //处理重新计算排名
  private async handleRegRanking(row: any) {
    //请求删除
    const res: any = await reCalH5Activity({ id: row.id });

    //重新计算成功
    if (res.status == 0) {
      //显示提示
      this.$message.success("重新计算成功");
    }
  }

  //获取道具名称
  private getPropName(id: number): string {
    //数据赋值
    for (var i: number = 0; i < this.propList.length; i++) {
      if (id == this.propList[i].propid) {
        return this.propList[i].propname;
      }
    }
    return "";
  }

  //获取活动名称
  private getTypeName(type: number): string {
    //数据赋值
    for (var i: number = 0; i < this.typeList.length; i++) {
      if (type == this.typeList[i].type) {
        return this.typeList[i].name;
      }
    }
    return "";
  }

  //返回参数
  private getExts(exts: string): Array<number> {
    //判断返回
    if (exts) {
      //数据赋值
      var extsJson: any = JSON.parse(exts);

      //判断赋值
      if (extsJson && extsJson.prop_ids) {
        return extsJson.prop_ids;
      }
    }
    return [];
  }

  //------------------------------ 添加活动 -----------------------------
  //定义变量
  private isCreate: boolean = false; //是否创建
  private addPropList: any[] = []; //添加道具列表
  private dialogShow: boolean = false; //显示随画框
  private dialogLoading: boolean = false; //对话框等待

  //表单数据
  private form: any = {
    //临时数据
    time: [], //时间
    rate_str: "", //钻石积分比例

    //上传参数
    id: 0, //ID
    type: 0, //活动类型
    name: "", //活动名字
    rate: 0, //钻石积分比例
    h5_url: "", //h5活动链接
    params: "", //辅助参数
    end_time: "", //活动结束时间
    start_time: "", //活动开始时间
  };

  //添加道具
  private addProp(): void {
    //数据赋值
    this.propOptions = [];
    for (var i: number = 0; i < this.propList.length; i++) {
      if (this.addPropList.indexOf(this.propList[i]) == -1) {
        this.propOptions.push(this.propList[i]);
      }
    }

    //显示对话框
    this.sendDialogShow = true;
  }

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogShow = false;

    //获取列表
    this.getList();
  }

  //处理创建
  private async createHandle() {
    //判断返回
    if (this.form.type == 0) return this.$message.error("请选择活动类型");
    if (this.form.name == "") return this.$message.error("请输入活动名称");
    if (this.form.h5_url == "") return this.$message.error("请输入h5活动链接");
    if (Number(this.form.rate_str) == 0) return this.$message.error("钻石积分比例 > 0");
    if (!this.form.time || this.form.time.length != 2) return this.$message.error("请输选择活动时间");
    if (this.form.type == 1 && this.addPropList.length == 0) return this.$message.error("请添加活动道具");
    var timeNum: number = new Date(this.form.time[1]).getTime() - new Date(this.form.time[0]).getTime();
    if (timeNum >= 30 * 24 * 3600 * 1000) return this.$message.error("活动时间最大为一个月 ");

    //数据赋值
    this.form.params = { prop_ids: [] };
    this.form.type = Number(this.form.type);
    if (this.form.type == 1) {
      for (var i: number = 0; i < this.addPropList.length; i++) {
        this.form.params.prop_ids.push(this.addPropList[i].propid);
      }
    }
    this.form.rate = Number(this.form.rate_str);

    //时间赋值
    if (this.form.time && this.form.time.length == 2) {
      this.form.end_time = this.form.time[1];
      this.form.start_time = this.form.time[0];
    } else {
      this.form.end_time = "";
      this.form.start_time = "";
    }

    //显示等待
    this.dialogLoading = true;

    //添加活动
    await addH5Activity(this.form);

    //显示提示
    this.$notify({ title: "成功", message: "添加成功", type: "success", duration: 2000 });

    //隐藏界面
    this.dialogLoading = false;
    this.dialogShow = false;

    //重新获取数据
    this.getList();
  }

  //处理编辑
  private async updataHandle() {
    //判断返回
    if (this.form.h5_url == "") return this.$message.error("请输入h5活动链接");

    //数据赋值
    this.form.params = { prop_ids: [] };
    this.form.type = Number(this.form.type);
    if (this.form.type == 1) {
      for (var i: number = 0; i < this.addPropList.length; i++) {
        this.form.params.prop_ids.push(this.addPropList[i].propid);
      }
    }
    this.form.rate = Number(this.form.rate_str);

    //时间赋值
    if (this.form.time && this.form.time.length == 2) {
      this.form.end_time = this.form.time[1];
      this.form.start_time = this.form.time[0];
    } else {
      this.form.end_time = "";
      this.form.start_time = "";
    }

    //显示等待
    this.dialogLoading = true;

    //添加活动
    await updateH5Activity(this.form);

    //显示提示
    this.$notify({ title: "成功", message: "编辑成功", type: "success", duration: 2000 });

    //隐藏界面
    this.dialogLoading = false;
    this.dialogShow = false;

    //重新获取数据
    this.getList();
  }

  //删除道具
  private delProp(index: any): void {
    //数据赋值
    this.addPropList.splice(index, 1);
  }

  //------------------------------ 道具列表 -----------------------------
  //定义变量
  private propOptions: any[] = [];
  private sendDialogShow: boolean = false; //显示对话框

  //取消按钮
  private btnSendCancel(): void {
    //隐藏界面
    this.sendDialogShow = false;
  }

  //添加道具
  private addGift(row: any): void {
    //数据赋值
    this.addPropList.push(row);

    //隐藏界面
    this.sendDialogShow = false;
  }

  //------------------------------ 道具列表 -----------------------------
  //定义变量
  private rankingList: any[] = [];
  private rankingTotal: number = 0;
  private rankingDialogShow: boolean = false;
  private rankingDialogLoading: boolean = false;

  //请求参数
  private rankingQuery: any = {
    //请求数据
    id: 0, //活动ID

    //页面数据
    page: 1, //分页
    page_size: 10, //每页行数
  };

  //取消按钮
  private btnRankingCancel(): void {
    //隐藏界面
    this.rankingDialogShow = false;
  }

  //获取排行数据
  private async getRankingList() {
    //显示等待
    this.rankingDialogLoading = true;

    //获取数据
    const { data } = await getH5ActivityRank(this.rankingQuery);

    //数据赋值
    this.rankingList = data.list;
    this.rankingTotal = data.total;

    //隐藏等待
    setTimeout(() => {
      this.rankingDialogLoading = false;
    }, 0.5 * 1000);
  }
}
